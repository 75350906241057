<template lang="pug">
Overlay(unique-key='doubleWarrantyModal' modal-width='SMALL')
  div(class='pt-6 text-center lg:pt-0')
    div(class='flex justify-center items-center gap-x-2 mb-4')
      img(src='/images/doubleWarrantyIcon.svg' width='24' height='24')
      span(class='text-secondary-dark font-bold text-2xl') Double Your Warranty

    p(class='mb-4') You can #[span(class='font-bold') DOUBLE] your warranty when purchasing a tonneau cover and professional installation from participating dealers!

    p(class='font-bold') Participating Tonneau Cover Brands
    p A.R.E., BAK, Extang, Retrax, Roll-N-Lock, Truxedo, UnderCover

  template(#footer)
    BaseButton(class='w-full' @click='close') Close
</template>

<script>
export default defineNuxtComponent({
  name: 'DealerLocatorDoubleWarrantyModal',
  methods: {
    close() {
      this.$overlay.close()
    },
  },
})
</script>
