<template lang="pug">
div
  //- hidden fields to throw off browser autocomplete
  div(class='absolute' style='right: 99999px')
    input(id='address' type='text' name='address')
    input(id='city' type='text' name='city')
    input(id='zip' type='text' name='zipcode')
  div(class='grid grid-cols-4 items-center')
    div(class='col-span-3')
      BaseFormInput(
        id='placesSearch'
        name='placesSearch'
        placeholder='Search by City or Zip'
        :classes='{ input: "h-[50px] rounded-r-none" }'
      )
    div(class='col-span-1')
      BaseFormSelect(
        id='radius'
        v-model='radius'
        name='radius'
        :options='{ 5: "5 mi", 10: "10 mi", 25: "25 mi", 50: "50 mi" }'
        :classes='{ input: "h-full rounded-l-none border-l-0", inner: "h-full", selectIcon: "w-7" }'
      )
</template>

<script setup lang="ts">
const { $googlePlaces } = useNuxtApp()
const dealerData = useDealerLocator()
const { isIntegrationEnabled } = useUtils()
const radius = ref(25)
let placesSearch: HTMLInputElement
let autocomplete: any
let autocompleteService: any
let placesService: any

/** Creates the Form and performs searches */
onMounted(async () => {
  if (!isIntegrationEnabled('googlePlaces')) return
  placesSearch = document.getElementById('placesSearch') as HTMLInputElement
  await $googlePlaces.load()
  placesSearch.value = currentLocation.value || ''
  dealerData.setRadius(radius.value)
  setupAutocomplete()
})

const currentLocation = computed(() => {
  return dealerData.state.value.location
})

function setupAutocomplete() {
  autocomplete = new window.google.maps.places.Autocomplete(placesSearch, {
    types: ['(regions)'],
    componentRestrictions: { country: ['us', 'ca'] },
  })

  // Add listener to the Autocomplete instance
  autocomplete.addListener('place_changed', () => {
    getLocationFromGoogle()
  })
}

async function getLocationFromGoogle() {
  const place = autocomplete.getPlace()

  if (place.geometry)
    await saveLocation(place.geometry.location.lat(), place.geometry.location.lng(), place.formatted_address)
  else if (place.name) {
    // Ex: The user hit Enter
    // Create AutocompleteService instance
    if (!autocompleteService) autocompleteService = new window.google.maps.places.AutocompleteService()
    // Create PlacesService instance
    if (!placesService) placesService = new window.google.maps.places.PlacesService(document.createElement('div'))

    try {
      // Get place prediction
      const { predictions } = await autocompleteService.getPlacePredictions({
        input: place.name,
        types: ['(regions)'],
        componentRestrictions: { country: ['us', 'ca'] },
      })

      // get first result from predictions
      const [prediction] = predictions

      // Make sure we have a prediction before we try to get more info about it
      if (!prediction) {
        placesSearch.value = ''
        return
      }
      // we have the first result from the list so we need to get its details to populate the form.
      await placesService.getDetails(
        {
          placeId: prediction.place_id,
          fields: ['geometry.location', 'formatted_address'],
        },
        (result: any, status: string) => {
          if (status !== 'OK') return

          saveLocation(result.geometry.location.lat(), result.geometry.location.lng(), result.formatted_address)
        }
      )
    } catch (e) {
      console.log('Unable to guess location', e)
    }
  }
}

async function saveLocation(lat: number, lng: number, newLocation: string) {
  // Set the location values if the values aren't nullish otherwise fallback to 0, 0 so we don't get any results
  dealerData.setLocation(lat, lng, newLocation)

  // Only set the input location we get given a value
  if (newLocation) {
    placesSearch.value = newLocation
  }

  // Trigger a new search
  await dealerData.performSearch()
}

watch(radius, (newVal) => {
  dealerData.setRadius(newVal)
  dealerData.performSearch()
})
</script>
